<template>
    <div id="trustpilot-widget" class="box">
        <!-- TrustBox widget - Slider -->
        <div id="trustbox" class="trustpilot-widget" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="50b7a9eb00006400051f090e" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5">
            <a href="https://uk.trustpilot.com/review/covered2go.co.uk" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TrustpilotBanner",
        mounted() {
            const trustboxRef = document.getElementById('trustbox');
            window.Trustpilot.loadFromElement(trustboxRef);
        }
    }
</script>

<style lang="scss" scoped>
    #trustpilot-widget {
        margin: 20px 0px 20px 0px;
        padding: 40px 0px 0px 0px;
    }
</style>